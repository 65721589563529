import React, { useRef, useState } from 'react';
import { Form, Input, Button, Upload, DatePicker, Select, Row, Col, Collapse, message, Image, Result, Dropdown, Menu, Divider, Modal } from 'antd';
import { CameraOutlined, CloseCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useApi } from '../../hooks/api';
import { getBase64Upload, getFileOriginal } from '../../utils/Upload';
import { getDate } from '../../utils/Date';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import SuccessContent from '../result/SuccessContent';
import Camera from '../camera';

import "./style.css";

dayjs.extend(customParseFormat);

const { Option } = Select;
const onChange = (key) => {
    console.log(key);
};


const GuestBookRegistrationForm = ({ onClose }) => {
    const [form] = Form.useForm();
    const { data, loading, error, refetch } = useApi('/api/kaldik-kegiatan/buku-tamu');
    const [modal, contextHolder] = Modal.useModal();
    const videoRef = useRef(null); // Untuk menyimpan referensi video stream
    const [currentCamera, setCurrentCamera] = useState('user'); // 'user' untuk kamera depan, 'environment' untuk kamera belakang

    const [preview, setPreview] = useState(null);
    const [previewUpload, setPreviewUpload] = useState(null);

    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [mediaStream, setMediaStream] = useState(null); // State untuk menyimpan stream

    const handleTakePhoto = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0);
        const dataUri = canvas.toDataURL('image/jpeg');
        setPreview(dataUri);
        setPreviewUpload(null);
    };


    const handleGetFile = async ({ fileList }) => {
        if (fileList && fileList.length > 0) {
            setPreview(URL.createObjectURL(fileList[0].originFileObj));
            let file = await getFileOriginal(fileList)
            setPreviewUpload(file);
        } else {
            setPreview(null);
            setPreviewUpload(null);
        }
    }

    const onFinish = async (values) => {
        let foto = null;
        if (!preview) {
            return message.warning('Harap upload foto profil terlebih dahulu!')
        }
        foto = getBase64Upload(preview);
        if (!foto) {
            if(!previewUpload) {
                return message.warning('Harap upload foto profil terlebih dahulu!')
            }
            foto = getBase64Upload(previewUpload)
        }
        if (!foto) {
            return message.warning('Harap upload foto profil terlebih dahulu!')
        }

        refetch("POST", {
            foto: foto,
            nama: values.name,
            tgl: getDate(values.tgl),
            telepon: values.phone,
            instansi: values.asalInstansi,
            jabatan: values.jabatan,
            jenis_tamu: values.jenisTamu,
            alamat: values?.address,
            tujuan: values.tujuanTamu,
            keperluan: values.keperluan,
            keterangan: values.keterangan,
        }).then(() => {
            if (data && data.status === true) {
                message.success(data.message);
                form.resetFields();
            }
        });
        // Reset form
        // Close modal
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        // Munculkan message warning jika ada error
        message.warning('Harap isi semua kolom yang diperlukan dengan benar sebelum submit!');
    };


    const handleOpenCamera = async () => {
        setIsCameraOpen(true);
        setPreview(null);
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: currentCamera } });
            setMediaStream(stream); // Menyimpan stream ke state
            if (videoRef.current) {
                videoRef.current.srcObject = stream; // Menghubungkan stream ke video
                await videoRef.current.play(); // Memastikan video mulai diputar
            }
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };

    const items = [
        {
            key: '1',
            label: 'Pendaftaran Tamu - Data Diri',
            children: <>
                {/* Nama Tamu */}
                <Form.Item
                    name="name"
                    label="Nama Tamu"
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: 'Harap masukkan nama!' }]}
                >
                    <Input placeholder="Masukkan nama lengkap" />
                </Form.Item>

                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="tgl"
                            label="Tanggal"
                            validateTrigger="onBlur"
                            rules={[{ required: true, message: 'Harap masukkan tanggal tamu!' }]}
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="Pilih tanggal" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        {/* No Telp */}
                        <Form.Item
                            name="phone"
                            label="No Telp"
                            validateTrigger="onBlur"
                            rules={[{
                                required: true,
                                message: 'Harap masukkan nomor telepon!'
                            }, {
                                pattern: /^\+?[0-9]*$/,
                                message: 'Nomor telepon tidak valid!',
                            }]}
                        >
                            <Input placeholder="Masukkan nomor telepon" type="tel" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="asalInstansi"
                            label="Asal Instansi"
                            validateTrigger="onBlur"
                            rules={[{ required: true, message: 'Harap masukkan asal instansi!' }]}
                        >
                            <Input placeholder="Masukkan asal instansi" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="jabatan"
                            label="Jabatan"
                            validateTrigger="onBlur"
                            rules={[{ required: true, message: 'Harap masukkan jabatan!' }]}
                        >
                            <Input placeholder="Masukkan jabatan" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="jenisTamu"
                    label="Jenis Tamu"
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: 'Harap pilih jenis tamu!' }]}
                >
                    <Select placeholder="Pilih jenis tamu">
                        <Option value="Kemenag">Kemenag</Option>
                        <Option value="Kemendikbud">Kemendikbud</Option>
                        <Option value="Wali Murid">Wali Murid</Option>
                        <Option value="TNI/Polri">TNI/Polri</Option>
                        <Option value="Teknisi/Jasa">Teknisi/Jasa</Option>
                        <Option value="Sales Marketing">Sales Marketing</Option>
                        <Option value="Lainnya">Lainnya</Option>
                    </Select>
                </Form.Item>

                <Form.Item name="address" label="Alamat" rules={[{ required: true, message: 'Harap masukkan alamat!' }]} >
                    <Input.TextArea rows={3} placeholder="Masukkan alamat lengkap"
                    />
                </Form.Item>
            </>,
        },
        {
            key: '2',
            label: 'Pendaftaran Tamu - Tujuan',
            children: <>
                <Form.Item
                    name="tujuanTamu"
                    label="Tujuan Tamu"
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: 'Harap pilih jenis tamu!' }]}
                >
                    <Select placeholder="Pilih jenis tamu">
                        <Option value="Kepala Madrasah">Kepala Madrasah</Option>
                        <Option value="Waka Kesiswaan">Waka Kesiswaan</Option>
                        <Option value="Waka Kurikulum">Waka Kurikulum</Option>
                        <Option value="Waka Humas">Waka Humas</Option>
                        <Option value="Waka Sarpras">Waka Sarpras</Option>
                        <Option value="Komite">Komite</Option>
                        <Option value="LITBANG">LITBANG</Option>
                        <Option value="Ma'had">Ma'had</Option>
                    </Select>
                </Form.Item>

                <Form.Item name="keperluan" label="Keperluan" rules={[{ required: true, message: 'Harap masukkan keperluan!' }]}>
                    <Input.TextArea rows={3} placeholder="Masukkan keperluan"
                    />
                </Form.Item>

                <Form.Item name="keterangan" label="Keterangan" rules={[{ required: true, message: 'Harap masukkan keterangan!' }]}>
                    <Input.TextArea rows={3} placeholder="Masukkan keterangan" />
                </Form.Item>
            </>
        },
    ];

    if (data && data.data) {
        return <SuccessContent
            title="Terima Kasih!"
            subTitle="Informasi tamu Anda telah kami terima, terima kasih!" />
    }

    // const confirmed = await modal.confirm(configConfirm);

    const onRenderButtonUpload = () => {
        return <Dropdown trigger={['click']} overlay={<Menu>
            <Menu.Item key={0} onClick={async () => {
                handleOpenCamera();
            }}><CameraOutlined />&nbsp;Buka Camera</Menu.Item>
            <Menu.Item key={1}>
                <Upload
                    maxCount={1}
                    showUploadList={false}
                    accept="image/*"
                    name="profile"
                    beforeUpload={() => false}
                    onChange={handleGetFile}><UploadOutlined />&nbsp;Pilih File</Upload>
            </Menu.Item>
        </Menu>}>
            <Button type="primary" style={{ margin: 16 }}>Upload Foto <CameraOutlined style={{ fontSize: 11 }} /></Button>
        </Dropdown>
    }

    const onRenderUpload = () => {
        return !isCameraOpen ? (<Result
            icon={<CameraOutlined />}
            title="Upload Foto Tamu"
            subTitle="Maaf, untuk melanjutkan Anda harus upload foto tamu terlebih dahulu."
            extra={onRenderButtonUpload()}
        />) : (
            <Camera
                videoRef={videoRef}
                setMediaStream={setMediaStream}
                setIsCameraOpen={setIsCameraOpen}
                setCurrentCamera={setCurrentCamera}
                handleOpenCamera={handleOpenCamera}
                onTakePhoto={handleTakePhoto}
                isCameraOpen={isCameraOpen}
                mediaStream={mediaStream}
                currentCamera={currentCamera}
            />
        )
    }

    const onRenderForm = () => {
        return (
            <>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{ gender: 'Laki-laki' }}
                >
                    {/* Upload Profile */}
                    <div style={{ textAlign: 'center' }}>
                        {preview && (
                            <>
                                <div className='close-preview-guestbook'><CloseCircleOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                    setPreview(null);
                                }} /></div>
                                <Image
                                    src={preview}
                                    alt="Preview"
                                    style={{ width: '100%', marginTop: '10px' }}
                                /></>
                        )}
                        <br />
                        {onRenderButtonUpload()}
                    </div>

                    <Collapse accordion items={items} defaultActiveKey={['1']} onChange={onChange} />

                    <Divider />

                    {/* Submit Button */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block loading={loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                {contextHolder}
            </>
        );
    }

    return preview ? onRenderForm() : onRenderUpload()
}

export default GuestBookRegistrationForm;